<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()">

    <template v-slot:activator="{ on: dialog }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            x-small
            v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Network</span>
      </v-tooltip>
    </template>

    <v-form v-model="isValid" ref="form" @submit.prevent="save">

      <v-card>

        <v-card-title class="headline">
          New Network
        </v-card-title>

        <v-divider />

        <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Channel"
                  outlined
                  disabled
                  :value="channel.name"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="network.name"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required, rules.min(2)]"
                  :background-color="isFieldDirty('name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  outlined
                  clearable
                  inputmode="email"
                  v-model="network.email"
                  :rules="[rules.emailOrEmpty]"
                  :background-color="isFieldDirty('email') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone"
                  inputmode="tel"
                  outlined
                  clearable
                  v-model="network.phone"
                  :background-color="isFieldDirty('phone') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Address"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="network.address"
                  :background-color="isFieldDirty('address') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="City"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="network.city"
                  :background-color="isFieldDirty('city') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Zip"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.zip"
                  :background-color="isFieldDirty('zip') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="6">
                <!--<v-text-field-->
                  <!--label="Country"-->
                  <!--outlined-->
                  <!--clearable-->
                  <!--v-model="network.country"-->
                  <!--:background-color="isFieldDirty('country') ? '#faf9f5' : ''"-->
                  <!--/>-->
                <v-autocomplete
                  v-model="network.country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Country"
                  outlined
                  :background-color="isFieldDirty('country') ? '#faf9f5' : ''">
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <!--<v-text-field-->
                  <!--label="Time Zone"-->
                  <!--outlined-->
                  <!--clearable-->
                  <!--v-model="network.time_zone"-->
                  <!--:background-color="isFieldDirty('time_zone') ? '#faf9f5' : ''"-->
                  <!--/>-->
                <v-autocomplete
                  v-model="network.timezone"
                  :items="timezones"
                  item-text="name"
                  item-value="utcOffsetStr"
                  label="Timezone"
                  outlined
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('timezone') ? '#faf9f5' : ''">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <!--<v-text-field-->
                  <!--label="Currency"-->
                  <!--inputmode="numeric"-->
                  <!--outlined-->
                  <!--clearable-->
                  <!--v-model="network.currency"-->
                  <!--:background-color="isFieldDirty('currency') ? '#faf9f5' : ''"-->
                  <!--/>-->
                <v-autocomplete
                  v-model="network.currency"
                  :items="currencies"
                  label="Currency"
                  outlined
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('currency') ? '#faf9f5' : ''">
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Tax %"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.tax_percentage"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('tax_percentage') ? '#faf9f5' : ''"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Load Limits
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Minimum"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.load_limit_min"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('load_limit_min') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Maximum"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.load_limit_max"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('load_limit_max') ? '#faf9f5' : ''"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Cashback Limits
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Minimum"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.cashback_limit_min"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('cashback_limit_min') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Maximum"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="network.cashback_limit_max"
                  hint="Required"
                  persistent-hint
                  :rules="[rules.required]"
                  :background-color="isFieldDirty('cashback_limit_max') ? '#faf9f5' : ''"
                  />
              </v-col>
            </v-row>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

          <v-btn text width="72" @click="dialog = false;">Cancel</v-btn>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

          <v-btn v-if="saving" width="72" text disabled>
            <v-progress-circular indeterminate color="primary" />
          </v-btn>

          <v-btn v-else text color="primary" type="submit" :disabled="!canSave">Create</v-btn>
        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import ct from 'countries-and-timezones'
import currencyCodes from 'currency-codes'

export default {
  props: ['clientId'],

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      countries: Object.values(ct.getAllCountries()),
      timezones: Object.values(ct.getAllTimezones()),
      currencies: currencyCodes.data.map(c => ({ text: `${c.currency} (${c.code})`, value: c.code })),

      channel: {},
      network: {},

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById']),

    canSave() { return this.isDirty() && this.isValid },
  },

  methods: {
    ...mapActions('Channel', ['addNetwork']),

    initData() {
      this.channel = this.channelById(this.$route.params.channel_id)
      this.network = { channel_id: this.channel.id, active: true }

      this.$refs.form.reset()
    },

    isFieldDirty(name) {
      return this.network[name]?.toString().trim() != ''
    },

    isDirty() {
      const network = _.pickBy(_.omit(this.network, ['active', 'channel_id']), _.identity)
      const isDirty = Object.keys(network).length > 0

      return isDirty
    },

    async save() {

      if (!this.canSave) return

      this.saving = true

      //let network = _.cloneDeep(this.network)
      //network.country = this.network.country?.id
      //network.timezone = this.network.timezone?.utcOffset
      //network.currency = this.network.currency?.value

      this.addNetwork(this.network)

      .then(addedNetwork => {
        this.saving = false
        this.dialog = false
        this.$router.push({name: 'network', params: { channel_id: this.channel.id, network_id: addedNetwork.id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error
        this.saving = false
      })
    }
  },

  watch: {

    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    }
  }
}
</script>

<style scoped>
.v-card__title {
  background-color: var(--v-primary-base);
  color: var(--v-headerText-base);
}
</style>

