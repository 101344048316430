<template>

  <v-container
    fluid
    class="ma-0 pa-0 pt-1"
    >

    <Confirm ref="confirm" />

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'channel'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

         <!--<v-list-item class="pt-0 px-2">-->

          <v-list-item class="overline pt-0 mt-0 pl-1">
            Channel
            <v-spacer />

            <div class="pl-2">
              <ChangeChannelDialog :channelId="channelId" />

              <v-tooltip bottom v-if="networks.length == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    :loading="deleting"
                    @click="deleteChannel">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Channel</span>
              </v-tooltip>
            </div>

          </v-list-item>

          <v-card
            outlined
            class="pa-0 ma-0 mt-2 flex-grow-1"
            style="">

            <v-card-title class="py-2">
              {{ channel.name }}
              <v-spacer />
              <v-icon :style="{display: channel.active ? 'none' : 'inline'}" color="red">mdi-minus-circle-outline</v-icon>
            </v-card-title>

            <v-card-text>
              <template v-if="channel.address">
                {{ channel.address }}
              </template>
              <template v-else-if="!channel.city || !channel.country || !channel.zip">
                <em>No Address</em>
              </template>

              <template v-if="channel.city && channel.country">
                <br />
                {{ channel.city }}, {{ countryName }}
              </template>

              <template v-else-if="channel.city">
                <br />
                {{ channel.city }}
              </template>

              <template v-else-if="channel.country">
                <br />
                {{ countryName }}
              </template>

              <template v-if="channel.zip">
                <br />
                {{ channel.zip }}
              </template>

              <template v-if="channel.email">
                <br />
                {{ channel.email }}
              </template>

              <template v-if="channel.phone">
                <br />
                {{ formatPhone(channel.phone) }}
              </template>

            </v-card-text>

          </v-card>

          <v-list
            v-model="networkSelectedIndex">

            <v-list-item class="overline pt-0 mt-0 pl-1">
              Channel Networks
              <v-spacer />

              <NewNetworkDialog :channelId="channelId" />
            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name, Email, Address, Phone, Tax, Limits"
                v-model="networkFilter"
                @click:clear="clearNetworkFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="network in filteredNetworkList"
              :key="network.id"
              link
              :to="{name: 'network', params: { network_id: network.id }}">

              <v-list-item-content>
                <v-list-item-title>
                  {{ network.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ network.tax_percentage }}%
                  &middot;
                  L {{ parseInt(network.load_limit_min) }}/{{ parseInt(network.load_limit_max) }}
                  &middot;
                  C {{ parseInt(network.cashback_limit_min) }}/{{ parseInt(network.cashback_limit_max) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="network.terminals_count">
                  Terminals {{ network.terminals_count || 0 }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  <em>No Terminals</em>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon color="red">
                  <template v-if="!channel.active && !network.active">mdi-minus-circle-multiple</template>
                  <template v-else-if="!channel.active">mdi-minus-circle-multiple-outline</template>
                  <template v-else-if="!network.active">mdi-minus-circle-outline</template>
                </v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-0 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import NewNetworkDialog from '@/components/NewNetworkDialog.vue'
import ChangeChannelDialog from '@/components/ChangeChannelDialog.vue'
import ct from 'countries-and-timezones'
import Confirm from '@/components/Confirm.vue'

export default {
  data() {
    return {
      channelId: undefined,
      channel: {},

      deleting: false,

      networkFilter: '',
      networkSelectedIndex: undefined,
      networkSelected: 0,
      filteredNetworkList: [],

      networkId: undefined,

      newNetworkDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Networks',
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'channels', 'networksByChannelId']),

    countryName() {
      return ct.getCountry(this.channel.country)?.name
    },

    networks() {
      return this.networksByChannelId(this.channelId) ?? []
    }
  },

  components: {
    NewNetworkDialog,
    ChangeChannelDialog,
    Confirm,
  },

  methods: {
    ...mapActions('Channel', [ 'getNetworkList', 'removeChannel' ]),

    clearNetworkFilter() {
      this.networkFilter = ''
    },

    filterNetworkList() {
      const filter = this.networkFilter?.toLowerCase() ?? ''
      const networks = this.networks

      const filteredNetworkList = filter.length == 0
        ? networks
        : networks.filter(network =>
            network.name?.toLowerCase().includes(filter) ||
            network.address?.toLowerCase().includes(filter) ||
            network.city?.toLowerCase().includes(filter) ||
            network.zip?.toLowerCase().includes(filter) ||
            network.country?.toLowerCase().includes(filter) ||
            network.email?.toLowerCase().includes(filter) ||
            network.phone?.toLowerCase().includes(filter) ||
            network.tax_percentage?.toString().toLowerCase().includes(filter) ||
            network.load_limit_min?.toString().toLowerCase().includes(filter) ||
            network.load_limit_max?.toString().toLowerCase().includes(filter) ||
            network.cashback_limit_min?.toString().toLowerCase().includes(filter) ||
            network.cashback_limit_max?.toString().toLowerCase().includes(filter)
          )

      this.filteredNetworkList.splice(0, this.filteredNetworkList.length, ...filteredNetworkList)
      //this.filteredNetworkList = [...filteredNetworkList]
    },

    deleteChannel() {

      this.$refs.confirm.open(
        'Delete Channel',
        'Deletion is permanent. Are you sure?',
      )

      .then(confirmed => {
        if (!confirmed) throw 0;

        this.deleting = true

        return this.removeChannel(this.channel.id)
      })

      .then(() => {
        return this.$router.push({name: 'channels'})
      })

      .catch(error => {
        if (!error) return

        this.error = error?.response?.data ?? error?.message

        console.error(this.error)
      })

      .finally(() => {
        this.deleting = false
      })
    },
  },

  watch: {

    '$route.params.channel_id': {
      handler(newValue) {
        this.channelId = newValue
        this.channel = this.channelById(newValue) ?? {}
      },
      immediate: true,
    },

    channels: {
      handler() {
        this.channel = this.channelById(this.channelId) ?? {}
      },
    },

    networks: {
      handler() {
        this.filterNetworkList()
      },
      immediate: true,
    },

    networkFilter: {
      handler() { 
        this.filterNetworkList()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>


